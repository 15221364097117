<template>
    <div class="m-0">
        <template v-for="button in buttons">
            <template v-if="button.type === 'QUICK_REPLY'">
                <b-button variant="light" class="m-0 text-primary border" disabled block>
                    <font-awesome-icon :icon="'reply'" />
                    {{ button.text }}
                </b-button>
            </template>
          <template v-else-if="button.type === 'URL'">
            <b-button variant="light" class="m-0 text-primary border" disabled block>
              <font-awesome-icon :icon="'link'" />
              {{ button.text }}
            </b-button>
          </template>
            <template v-else>
                <span v-b-tooltip.hover :title="button.phone_number">
                    <b-button variant="light" class="m-0 text-primary border" disabled block>
                        <font-awesome-icon :icon="'phone-alt'" />
                        {{ button.text }}
                    </b-button>
                </span>
            </template>
        </template>
       
    </div>
</template>

<script>
export default {

    props: ['buttons']
}
</script>