<template>
    <b-container fluid>
        <b-row>
            <b-col class="pt-2">
                <div class="px-3">
                    <h4>{{ $t('simple.create_campaign') }}</h4>
                </div>
            </b-col>
        </b-row>
        <b-row class="px-2">
            <b-container fluid>
                <v-wait for="answer">
                    <loading slot="waiting" />
                <b-row>
                    <b-col>
                        
                        <b-card v-if="activeCard === 1">
                            <template #header>
                                <span class="font-weight-bold"> {{ $t('simple.audience') }}</span>
                            </template>
                            <div class="px-2">

                                <b-row class="mb-2">
                                    <b-col class="d-flex justify-content-between">
                                        <b-button variant="success" @click="addUsers">
                                            {{ $t('simple.add_users') }}</b-button>
                                        <b-button variant="danger" class="ml-2" @click="deleteUsers"
                                            :disabled="!selected.length">
                                            {{ $t('simple.delete_users') }}</b-button>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <b-pagination hide-goto-end-buttons size="sm" :total-rows="total" :value="current"
                                            @input="updatePage" :per-page="perpage">
                                        </b-pagination>
                                    </b-col>
                                    <b-col md="4" class="my-2 ml-auto">
                                        <b-input-group>
                                            <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                            <b-input-group-append>
                                                <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-row>


                                <b-table stacked="md" responsive bordered hover 
                                    :filter="filter" 
                                    :per-page="perpage"
                                    :items="whatsapp_campaign_users"
                                    :select-mode="selectMode" selectable @row-selected="onRowSelected"
                                    :fields="window_width >= 1200 ? whatsapp_campaign_users_fields : whatsapp_campaign_users_fields_collapsed">
                                    <template #cell(select)="{ rowSelected }" >
                                        <template v-if="rowSelected">
                                            <span aria-hidden="true">&check;</span>
                                        </template>
                                        <template v-else>
                                            <span aria-hidden="true">&nbsp;</span>
                                        </template>
                                    </template>

                                    <template slot="cell(actions)" slot-scope="cell">
                                        <b-button size="sm" @click="cell.toggleDetails()" v-if="window_width < 1200">
                                            {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                        </b-button>
                                    </template>
                                    
                                    <template #row-details="row">
                                        <b-table small stacked="md" class="d-xl-none" responsive bordered hover
                                            :items="[row.item]" :fields="whatsapp_campaign_users_fields_not_collapsed">
                                            <template #cell(select)="{ rowSelected }">
                                                <template v-if="rowSelected">
                                                    <span aria-hidden="true">&check;</span>
                                                </template>
                                                <template v-else>
                                                    <span aria-hidden="true">&nbsp;</span>
                                                </template>
                                            </template>
                                            
                                            <!-- Created at -->
                                            <template slot="cell(created_at)" slot-scope="cell">
                                                {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}
                                            </template>
                                            <!-- Updated at -->
                                            <template slot="cell(updated_at)" slot-scope="cell">
                                                {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm') }}
                                            </template>
                                        </b-table>
                                    </template>
                                    <!-- Created at -->
                                    <template slot="cell(created_at)" slot-scope="cell">
                                        {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm')}}
                                    </template>
                                    <!-- Updated at -->
                                    <template slot="cell(updated_at)" slot-scope="cell">
                                        {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm')}}
                                    </template>
                                    
                                </b-table>
                                <v-wait for="whatsapp_campaign_users">
                                    <loading slot="waiting" />
                                </v-wait>

                            </div>

                            <template #footer>
                                <b-row class="align-items-center">
                                    <b-col class="d-flex">
                                        <b-button variant="danger" to="/settings/whatsapp-campaigns">{{
                                            $t('default.cancel') }} </b-button>
                                        <b-button variant="success" class="ml-auto" @click.prevent="activeCard++">
                                            {{ $t('pagination.next') }}</b-button>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-card>
                        <b-card v-if="activeCard === 2">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="font-weight-bold"> {{ $t('macros.whatsapp_template') }}</span>
                                    <b-button variant="outline-danger" pill size="sm" @click="unselectTemplate"
                                        v-show="whatsapp_campaign.whatsapp_template" class="ml-auto">
                                        {{ $t('macros.change_template') }}
                                    </b-button>
                                </div>
                            </template>

                            <template v-if="!whatsapp_campaign.whatsapp_template">
                                
                                    <b-row>
                                        <b-col md="4" class="my-2 ml-auto">
                                            <b-input-group>
                                                <b-form-input v-model="filterTemplate"
                                                    :placeholder="$t('simple.type_to_search')" />
                                                <b-input-group-append>
                                                    <b-btn :disabled="!filterTemplate" @click="filter = ''">{{
                                                        $t('simple.clear') }}</b-btn>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>

                                    <b-table stacked="lg" responsive bordered hover show-empty
                                        :items="approved_templates"
                                        :fields="window_width >= 1200 ? template_message_fields : template_message_fields_collapsed"
                                        :filter="filterTemplate">

                                        <!-- Actions -->
                                        <template slot="cell(actions)" slot-scope="cell">
                                            <b-button size="sm" @click="cell.toggleDetails()" v-if="window_width < 1200">
                                                {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                            </b-button>

                                            <b-button size="sm" @click.prevent="selectTemplate(cell.item.id)">Select</b-button>

                                        </template>

                                        <template slot="cell(content)" slot-scope="cell">
                                            <span class="font-weight-bold" v-if="cell.item.header">{{ cell.item.header
                                                }} | </span>{{ cell.item.content }}
                                        </template>

                                        <template #row-details="row">
                                            <b-table small stacked="md" class="d-xl-none" responsive bordered hover
                                                :items="[row.item]" :fields="template_message_fields_not_collapsed">
                                                <!-- Created at -->
                                                <template slot="cell(created_at)" slot-scope="cell">
                                                    {{
                                                        $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}
                                                </template>

                                            </b-table>
                                        </template>

                                        <!-- Created at -->
                                        <template slot="cell(created_at)" slot-scope="cell">
                                            {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}
                                        </template>


                                        <!-- Buttons  -->
                                        <template slot="cell(buttons)" slot-scope="cell">
                                            <span class="d-inline-block text-truncate" :style="{ maxWidth: '150px' }"
                                                v-b-tooltip.hover :title="template_button_normalize(cell.item.buttons)">
                                                {{ template_button_normalize(cell.item.buttons) }}
                                            </span>
                                        </template>


                                    </b-table>
                                    <v-wait for="approved_template_messages">
                                        <loading slot="waiting" />
                                    </v-wait>
                            </template>
                            <template v-else>

                                <b-card class="mb-1">
                                    <template #header>
                                        <div class="d-flex align-items-center">
                                            <div><span class="font-weight-bold">{{ $t('macros.template_details')
                                                    }}</span></div>
                                        </div>
                                    </template>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_account_name" class="font-weight-bold">
                                                {{ $t('simple.account') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="template_account_name" name="template_account_name"
                                                v-model="whatsapp_campaign.whatsapp_template.account_name" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_account_username" class="font-weight-bold">
                                                {{ $t('customer.phone') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="template_account_username"
                                                name="template_account_username"
                                                v-model="whatsapp_campaign.whatsapp_template.account_username" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_name" class="font-weight-bold">
                                                {{ $t('macros.template_name') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="template_message_name" name="template_message_name"
                                                v-model="whatsapp_campaign.whatsapp_template.name" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_category" class="font-weight-bold">
                                                {{ $t('macros.template_type') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="template_message_category" name="template_message_name"
                                                v-model="whatsapp_campaign.whatsapp_template.category" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_vertical" class="font-weight-bold">
                                                {{ $t('macros.template_vertical') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="template_message_vertical" name="template_message_name"
                                                v-model="whatsapp_campaign.whatsapp_template.vertical" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <template v-if="isHeaderActive">
                                    <b-card class="mt-4 mb-1">
                                        <template #header>
                                            <span class="font-weight-bold">{{ $t('macros.header') }}</span>
                                        </template>
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="template_message_header_type" class="font-weight-bold">{{$t('macros.header_type') }}:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                <b-form-input id="template_message_header_type"
                                                    name="template_message_header_type"
                                                    v-model="whatsapp_campaign.whatsapp_template.template_type" disabled></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="align-items-center mb-3" v-if="whatsapp_campaign.whatsapp_template.template_type === 'TEXT'">
                                            <b-col sm="3">
                                                <label for="template_message_header_text" class="font-weight-bold">{{
                                                    $t('macros.header') }}:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                    <b-form-input id="template_message_vertical" v-model="whatsapp_campaign.whatsapp_template.header"
                                                        :maxlength="template_message_settings.header.text_limit" disabled></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="align-items-center mb-3" v-if="whatsapp_campaign.whatsapp_template.template_type !== 'TEXT'">
                                            <b-col sm="3">
                                                <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                            </b-col>
                                            <b-col sm="12" lg="4" class="mb-3">
                                                <div :class="{ 'isInvalid': !file_url }"
                                                    class="media-upload-container d-flex justify-content-center align-items-center">
                                                    <div class="position-absolute">
                                                        <div class="d-flex flex-column align-items-center text-center">
                                                            <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                                v-if="!file_url">{{ $t('macros.attach_file') }}</span>
                                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                                v-else>{{ $t('macros.change_file') }}</span>
                                                            <template v-if="whatsapp_campaign.whatsapp_template.template_type === 'VIDEO'">
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(MP4)</span>
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(Max 20 MB)</span>
                                                            </template>
                                                            <template v-else-if="whatsapp_campaign.whatsapp_template.template_type === 'IMAGE'">
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(JPEG or PNG)</span>
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(Max 2MB)</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <template v-if="whatsapp_campaign.whatsapp_template.template_type === 'IMAGE'">
                                                        <b-form-file id="template_message_media" v-model="file"
                                                            :accept="template_message_settings.header.image_accepted_types" plain
                                                            @input="addFile(file, template_message_settings.header.image_size_limit)"
                                                            class="media-upload-button h-100 w-100">
                                                        </b-form-file>
                                                    </template>
                                                    <template v-else-if="whatsapp_campaign.whatsapp_template.template_type === 'VIDEO'">
                                                        <b-form-file id="template_message_media" v-model="file"
                                                            :accept="template_message_settings.header.video_accepted_types" plain
                                                            @input="addFile(file, template_message_settings.header.video_size_limit)"
                                                            class="media-upload-button h-100 w-100">
                                                        </b-form-file>
                                                    </template>
                                                </div>
                                            </b-col>
                                            <b-col sm="4" v-if="file_url" class="mb-3">
                                                <div class="position-relative media-preview-container">
                                                    <template v-if="whatsapp_campaign.whatsapp_template.template_type === 'VIDEO'">
                                                        <a href="#" @click="video_view(file_url)">
                                                            <video :src="file_url" class="media-preview">
                                                            </video>
                                                            <font-awesome-icon :icon="'play-circle'"
                                                                class="preview-video-button position-absolute p-0" />
                                                        </a>
                                                    </template>
                                                    <template v-else-if="whatsapp_campaign.whatsapp_template.template_type === 'IMAGE'">
                                                        <a href="#" @click="image_view(file_url)">
                                                            <b-img fluid :src="file_url"
                                                                class="media-preview" />
                                                        </a>
                                                    </template>
                                                    <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                                        v-if="file"
                                                        @click="removeFile">
                                                        <font-awesome-icon :icon="'times'" />
                                                    </b-btn>
                                                </div>
                                            </b-col>
                                        </b-row>
                               
                                    </b-card>
                                </template>

                                <b-card class="mb-1">
                                    <template #header>
                                        <div class="d-flex align-items-center">
                                            <div><span class="font-weight-bold">{{ $t('macros.body') }}</span></div>
                                        </div>
                                    </template>
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="template_message_content" class="font-weight-bold">
                                                {{ $t('macros.body_text') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <div class="position-relative">
                                                <b-form-textarea name="template_message_content"
                                                    v-model="whatsapp_campaign.whatsapp_template.content" rows="5" max-rows="6"
                                                    disabled></b-form-textarea>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <ValidationObserver ref="variables">
                                        <template v-if="whatsapp_campaign.whatsapp_template.content_variables_count">
                                            <b-card class="mt-4">
                                                <template #header>
                                                    <span class="font-weight-bold">{{ $t('macros.template_variables')
                                                        }}</span>
                                                </template>
                                                <b-card bg-variant="light" border-variant="info" class="mb-1"
                                                    v-for=" variable in template_variables" :key="variable.key">
                                                    <b-row class="align-items-center">
                                                        <b-col sm="3">
                                                            <label for="custom_variable_name" class="font-weight-bold">
                                                                {{ $t('macros.template_variable') }}:</label>
                                                            <b-form-input id="custom_variable_name"
                                                                v-model="variable.key" disabled> </b-form-input>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <label for="custom_variable_option"
                                                                class="font-weight-bold">
                                                                {{ $t('simple.type') }}:</label>
                                                            <b-form-select v-model="variable.selected_variable_option"
                                                                :options="variable_options"></b-form-select>
                                                        </b-col>
                                                        <b-col sm="4"
                                                            v-if="variable.selected_variable_option === 'custom'">
                                                            <label for="macros.template_variable_value"
                                                                class="font-weight-bold">
                                                                {{ $t('macros.template_variable_value') }}:</label>
                                                            <ValidationProvider rules="required"
                                                                v-slot="{ errors, valid }" :name="variable.key">
                                                                <b-form-input id="macros.template_variable_value"
                                                                    v-model="variable.value"
                                                                    :state="valid"></b-form-input>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="4" v-else>
                                                            <label for="macros.template_variable_value"
                                                                class="font-weight-bold">
                                                                {{ $t('simple.fallback_value') }}:</label>
                                                            <ValidationProvider rules="required"
                                                                v-slot="{ errors, valid }" :name="variable.key">
                                                                <b-form-input id="macros.template_variable_value"
                                                                    v-model="variable.value"
                                                                    :state="valid"></b-form-input>
                                                            </ValidationProvider>
                                                        </b-col>

                                                    </b-row>
                                                </b-card>
                                            </b-card>
                                        </template>
                                    </ValidationObserver>

                                    <template v-if="whatsapp_campaign.whatsapp_template.buttons">
                                        <b-card class="mt-4">
                                            <template #header>
                                                <span class="font-weight-bold">{{ $t('macros.buttons') }}</span>
                                            </template>
                                            <b-card bg-variant="light" border-variant="info" class="mb-1"
                                                v-for=" (button) in whatsapp_campaign.whatsapp_template.buttons" :key="button.text">
                                                <b-row class="align-items-center">
                                                    <b-col sm="3">
                                                        <label for="button_list_option" class="font-weight-bold">
                                                            {{ $t('macros.button_type') }}</label>
                                                        <b-form-input id="button_list_option" v-model="button.type"
                                                            disabled>
                                                        </b-form-input>
                                                    </b-col>
                                                    <b-col :sm="button.type === 'PHONE_NUMBER' ? 4 : button.type === 'URL' ? 3 : 8">
                                                        <label for="button_text" class="font-weight-bold">
                                                            {{ $t('macros.button_text') }}:</label>
                                                        <b-form-input id="button_text" v-model="button.text"
                                                            disabled></b-form-input>
                                                    </b-col>
                                                  <b-col :sm="3" v-if="button.type==='URL'">
                                                    <label for="button_url" class="font-weight-bold">
                                                      {{ $t('simple.url') }}:</label>
                                                    <b-form-input id="button_url" v-model="button.url"
                                                                  disabled></b-form-input>
                                                  </b-col>
                                                  <b-col :sm="3" v-if="button.type==='URL'">
                                                    <label for="button_variable" class="font-weight-bold">
                                                      {{ $t('simple.url_variable') }}:</label>
                                                    <b-form-input id="button_variable" v-model="button.variable"
                                                    ></b-form-input>
                                                  </b-col>


                                                    <b-col sm="4" v-if="button.type === 'PHONE_NUMBER'">
                                                        <label for="button_phone" class="font-weight-bold">
                                                            {{ $t('simple.phone_number') }}:</label>
                                                        <b-form-input id="button_phone" v-model="button.phone_number"
                                                            disabled></b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </b-card>
                                    </template>
                                </b-card>
                            </template>


                            <template #footer>
                                <b-row class="align-items-center">
                                    <b-col class="d-flex">
                                        <b-button variant="danger" to="/settings/whatsapp-campaigns">{{$t('default.cancel') }} </b-button>
                                        <!-- TODO!! Check -->
                                        <b-button variant="success" class="ml-auto" @click.prevent="goPreview"
                                            v-if="whatsapp_campaign.whatsapp_template">{{ $t('pagination.next') }}</b-button>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-card>

                        <v-wait for="whatsapp_campaign_edit">
                            <loading slot="waiting" />

                            <b-card v-if="activeCard === 3">
                                <template #header>
                                    <span class="font-weight-bold"> {{ $t('simple.campaign_overview') }}</span>
                                </template>

                                <b-row class="align-items-center">
                                    <b-col class="d-flex align-items-center">
                                        <h6 class="font-weight-bold text-info mr-auto">WhatsApp {{
                                            $t('simple.account') }}:</h6>
                                        <!-- <span class="text-info" role="button" @click.prevent="activeCard = 1">
                                            <font-awesome-icon :icon="['fas', 'edit']"  />
                                        </span> -->
                                    </b-col>
                                </b-row>
                                <b-row class="align-items-center">
                                    <b-col>
                                        <p class="mr-auto text-muted">{{ whatsapp_campaign.whatsapp_template.account_name }} ( {{
                                            whatsapp_campaign.whatsapp_template.account_username }} )</p>
                                    </b-col>
                                </b-row>

                                <hr>

                                <b-row class="align-items-center">
                                    <b-col class="d-flex align-items-center">
                                        <h6 class="font-weight-bold text-info mr-auto">WhatsApp {{
                                            $t('simple.campaign_name') }}:</h6>
                                        <span class="mr-3" role="button" @click.prevent="editName = !editName">
                                            <font-awesome-icon :icon="'edit'" v-if="!editName" class="text-info" />
                                            <font-awesome-icon :icon="'check'" v-else class="text-success" />
                                        </span>
                                    </b-col>
                                </b-row>

                                <ValidationObserver ref="campaign_name">
                                    <template v-if="!editName">
                                        <b-row class="align-items-center">
                                            <b-col>
                                                <p class="mr-auto text-muted">{{ whatsapp_campaign.name }}</p>
                                            </b-col>
                                        </b-row>
                                    </template>

                                    <template v-else>
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                            <b-row class="align-items-center">
                                                <b-col>
                                                    <b-form-input v-model="whatsapp_campaign.name"
                                                        @keyup.enter="editName = !editName"
                                                        :state="valid"></b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                    </template>
                                </ValidationObserver>


                                <hr>

                                <b-row class="align-items-center">
                                    <b-col class="d-flex align-items-center">
                                        <h6 class="font-weight-bold text-info mr-auto">WhatsApp {{
                                            $t('simple.campaign') }} {{ $t('simple.audience') }}:</h6>
                                        <span class="text-info mr-3" role="button" @click.prevent="activeCard = 1">
                                            <font-awesome-icon :icon="['fas', 'edit']" />
                                        </span>
                                    </b-col>
                                </b-row>

                                <b-row class="align-items-center">
                                    <b-col>
                                        <p class="mr-auto text-muted">{{ whatsapp_campaign_users.length > 0 ?
                                            whatsapp_campaign_users.length : 0 }}
                                            {{ $t('reports.customers') }}</p>
                                    </b-col>
                                </b-row>

                                <hr>
                                <template v-if="template_variables.length > 0">
                                    <b-row class="align-items-center">
                                        <b-col class="d-flex align-items-center">
                                            <h6 class="font-weight-bold text-info mr-auto">{{
                                                $t('macros.template_variables') }}:</h6>
                                            <span class="text-info mr-3" role="button" @click.prevent="activeCard = 2">
                                                <font-awesome-icon :icon="['fas', 'edit']" />
                                            </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-2">
                                        <b-col class="d-flex align-items-center">
                                            <div class="mr-auto text-muted">
                                                <p>{{ template_variables_list }} </p>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <hr>
                                </template>


                                <b-row class="align-items-center">
                                    <b-col class="d-flex align-items-center">
                                        <h6 class="font-weight-bold text-info mr-auto">WhatsApp {{
                                            $t('macros.template_details') }}:</h6>
                                        <span class="text-info mr-3" role="button" @click.prevent="activeCard = 2">
                                            <font-awesome-icon :icon="['fas', 'edit']" />
                                        </span>
                                    </b-col>
                                </b-row>
                                <b-row class="align-items-center mb-2">
                                    <b-col sm='12' class="align-items-center mb-2">
                                        <h6 class="font-weight-bold"
                                            v-if="whatsapp_campaign.whatsapp_template.header && whatsapp_campaign.whatsapp_template.template_type === 'TEXT'">
                                            {{
                                                whatsapp_campaign.whatsapp_template.header }}</h6>
                                    </b-col>
                                    <b-col sm='12' class="align-items-center mb-2"
                                        v-if="whatsapp_campaign.whatsapp_template.template_type !== 'TEXT'">
                                        <div class="position-relative media-preview-container">
                                            <template v-if="whatsapp_campaign.whatsapp_template.template_type === 'VIDEO'">
                                                <a href="#" @click="video_view(file_url)">
                                                    <video :src="file_url" class="media-preview">
                                                    </video>
                                                    <font-awesome-icon :icon="'play-circle'"
                                                        class="preview-video-button position-absolute p-0" />
                                                </a>
                                            </template>
                                            <template v-else-if="whatsapp_campaign.whatsapp_template.template_type === 'IMAGE'">
                                                <a href="#" @click="image_view(file_url)">
                                                    <b-img fluid :src="file_url"
                                                        class="media-preview" />
                                                </a>
                                            </template>
                                        </div>
                                    </b-col>
                                    <b-col sm='12' class="align-items-center mb-2">
                                        <p>{{ whatsapp_campaign.whatsapp_template.content }} </p>
                                    </b-col>
                                    <b-col sm='12' md="6" class="align-items-center mb-2"
                                        v-if="whatsapp_campaign.whatsapp_template.buttons">
                                        <message-template-buttons
                                            :buttons="whatsapp_campaign.whatsapp_template.buttons"></message-template-buttons>
                                    </b-col>
                                </b-row>


                                <template #footer>
                                    <b-row class="align-items-center">
                                        <b-col class="d-flex">
                                            <b-button variant="danger" to="/settings/whatsapp-campaigns">{{
                                                $t('default.cancel') }} </b-button>
                                            <b-button variant="success" class="ml-auto" @click.prevent="saveCampaign">{{
                                                $t('simple.save') }}</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-card>
                        </v-wait>
                    </b-col>
                </b-row>
                </v-wait>
            </b-container>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import WhatsappCampaignUsersEdit from './WhatsappCampaignUsersEdit.vue'
import ImageView from '../../Modal/ImageView.vue';
import VideoView from '../../Modal/VideoView.vue';
import { create } from 'vue-modal-dialogs'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    async mounted() {
        await this.fetchAccounts()

        await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })

        await this.fetchApprovedTemplateMessages(this.whatsapp_account)

        await this.fetchWhatsappCampaignDetails({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })

        this.whatsapp_campaign = {...this.whatsapp_campaing_details}

        if (this.whatsapp_campaing_details.whatsapp_template) {
            this.selectTemplate(this.whatsapp_campaing_details.whatsapp_template.id)
        }


        this.$nextTick(async () => {
            window.addEventListener('resize', this.onResize);
        })

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);

        this.resetWhatsappCampaignUsers()
    },

    data() {
        return {
            window_width: window.innerWidth,

            activeCard: 1,

            file_url: '',
            file: null,

           
            template_variables: [],
            attachment_id: null,
            editName: false,


            // TODO: Generate options from excel
            variable_options: [
                { value: "custom", text: "Custom" },
            ],

            filterTemplate: null,
            template_message_fields: [
                { key: 'name', label: this.$t('macros.template_name'), sortable: true },
                { key: 'content', label: this.$t('simple.content'), sortable: true, thStyle: { width: "20%" }, },
                { key: 'buttons', label: this.$t('macros.buttons') },
                { key: 'category', label: this.$t('default.category'), sortable: true },
                { key: 'template_type', label: this.$t('macros.template_type'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            template_message_fields_collapsed: [
                { key: 'name', label: this.$t('macros.template_name'), sortable: true },
                { key: 'content', label: this.$t('simple.content'), sortable: true, thStyle: { width: "20%" }, },
                { key: 'buttons', label: this.$t('macros.buttons'), thStyle: { 'max-width': "10%" }, },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            template_message_fields_not_collapsed: [
                { key: 'category', label: this.$t('default.category'), sortable: true },
                { key: 'template_type', label: this.$t('macros.template_type'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
            ],


            selectMode: 'multi',
            selected: [],

            filter: null,
            whatsapp_campaign_users_fields: [
                { key: 'select', label: this.$t('simple.select'), sortable: true },
                { key: 'phone_number', label: this.$t('simple.phone_number'), sortable: true },
                { key: 'name', label: this.$t('customer.name'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
            ],

            whatsapp_campaign_users_fields_collapsed: [
                { key: 'select', label: this.$t('simple.select'), sortable: true },
                { key: 'phone_number', label: this.$t('simple.phone_number'), sortable: true },
                { key: 'name', label: this.$t('customer.name'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            whatsapp_campaign_users_fields_not_collapsed: [
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
            ],



            whatsapp_campaign: {}
           
        }
    },

    methods: {
        ...mapActions('Templates', [
            'fetchApprovedTemplateMessages',
            'updateWhatsappCampaign',
            'fetchWhatsappCampaingUsers',
            'deleteWhatsappCampaignUsers',
            'updateWhatsappCampaignUsersPage',
            'fetchWhatsappCampaignDetails',
            'resetWhatsappCampaignUsers',
        ]),
        ...mapActions('Accounts', [
            'fetchAccounts',
        ]),

        ...mapActions('Reply', [
            'uploadFile',
            'resetReplyData'
        ]),

        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    this.file_url = URL.createObjectURL(file);

                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));

                    this.removeFile();
                }
            }
        },

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        removeFile() {
            this.file = null
            if(this.whatsapp_campaign.whatsapp_template.attachment) {
                this.file_url = this.whatsapp_campaign.whatsapp_template.attachment
            }
        },

        updatePage(data){
            this.updateWhatsappCampaignUsersPage({account_id: this.$route.params.account_id , campaign_id: this.$route.params.campaign_id , page: data})
        },

        onResize() {
            this.window_width = window.innerWidth;
        },

        // Show image
        image_view: async function (attachment) {

            // Show image modal
            const image_view = create(ImageView, 'attachment');

            if (await image_view(attachment)) {
                // Nothing here yet

            }
        },

        // Play video
        video_view: async function (attachment) {

            // Show video modal
            const video_view = create(VideoView, 'attachment');

            if (await video_view(attachment)) {
                // Nothing here yet

            }
        },


        template_button_normalize(buttons) {
            if (buttons) {
                return buttons.flatMap(button => {
                    if (button.type === 'QUICK_REPLY') {
                        return `${button.type}(${button.text}) `
                    }   else if (button.type === 'URL') {
                    return `${button.type}(${button.url}) `
                  }
                    return `${button.type}(${button.phone_number}) `
                })
            }
            return '-'
        },

        selectTemplate(template_id) {
            this.whatsapp_campaign.whatsapp_template = this.approved_templates.filter(template => template.id === template_id)[0]
            if(this.whatsapp_campaign.extra_data['content_variables'] && this.whatsapp_campaign.whatsapp_template.id === this.whatsapp_campaing_details.whatsapp_template.id) {
                this.template_variables = this.generateTemplateVariables(this.whatsapp_campaign.extra_data['content_variables'])
            } else {
                this.template_variables = this.generateTemplateVariables()
            }
            if(this.whatsapp_campaign.attachment && this.whatsapp_campaign.whatsapp_template.id === this.whatsapp_campaing_details.whatsapp_template.id ) {
                this.file_url = this.whatsapp_campaign.attachment
            } else {
                this.file_url = this.whatsapp_campaign.whatsapp_template.attachment
            }

        },

        unselectTemplate() {
            this.file = null
            this.file_url = ''
          
            this.whatsapp_campaign.whatsapp_template = null
            this.template_variables = []
        },

        async goPreview() {
            const isValid = await this.$refs.variables.validate();
            if (!isValid) return
            this.activeCard += 1
        },

        generateTemplateVariables(array = []) {
            const variables = this.whatsapp_campaign.whatsapp_template.content.match(/{{\d}}/g) ?? []
            const custom_variables = []
            variables.forEach((variable, index) => {
                custom_variables.push({
                    key: variable,
                    value: array[index] ?? '',
                    selected_variable_option: 'custom',
                })
            })
            return custom_variables
        },



        onRowSelected(items) {
            this.selected = items.flatMap((item) => item.id)
        },

        async addUsers() {
            const edit_users = create(WhatsappCampaignUsersEdit)

            await edit_users()
        },

        async deleteUsers() {
            await this.deleteWhatsappCampaignUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id, customer_ids: this.selected })
            await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })
        },


        async saveCampaign() {
            const isValid = await this.$refs.campaign_name.validate();
            if (!isValid) return

            let variables = null
            if(this.template_variables.length > 0) {
                variables = this.template_variables.flatMap(variable => variable.value)
            }

            if(this.file) {
                const formData = new FormData();
                formData.append('files', this.file);
                formData.append('account_id', this.$route.params.account_id);

                await this.uploadFile(formData)


                this.attachment_id = this.fileID[0]
            }

            const campaign = {
                name: this.whatsapp_campaign.name,
                whatsapp_template_id: this.whatsapp_campaign.whatsapp_template.id,
                content_variables: variables,
                buttons: this.whatsapp_campaign.whatsapp_template.buttons,
                attachment_id: this.attachment_id
            }



            await this.updateWhatsappCampaign({ account_id: this.$route.params.account_id , campaign_id: this.$route.params.campaign_id, campaign })
                .then(() => {
                    this.resetReplyData();
                    this.$router.push({ path: '/settings/whatsapp-campaigns' });
                })

        }

    },
    computed: {
        ...mapGetters({
            whatsapp_campaign_settings: 'Templates/getWhatsappCampaignSettings',
            whatsapp_campaing_details: 'Templates/getWhatsappCampaignDetails',
            accounts: 'Accounts/getAccounts',
            approved_templates: 'Templates/getApprovedTemplateMessages',
            whatsapp_campaign_users: 'Templates/getCampaignUsers',
            template_message_settings: 'Templates/getTemplateMessageSettings',
            fileID: 'Reply/getAttachFiles',

            total: 'Templates/getCampaignUsersTotal',
            current: 'Templates/getCampaignUsersCurrent',
            perpage: 'Templates/getCampaignUsersPerPage',

        }),


        whatsapp_account() {
            return this.accounts.filter(account => account.id === +this.$route.params.account_id)
        },


        template_variables_list() {
            let variable_list = ''
            if (this.template_variables.length > 0) {
                this.template_variables.forEach((variable, index) => index === 0 ? variable_list += variable.value : variable_list += ` | ${variable.value}`)
            }
            return variable_list
        },

        isHeaderActive() {
            if (this.whatsapp_campaign.whatsapp_template) {
                return this.whatsapp_campaign.whatsapp_template.header || this.whatsapp_campaign.whatsapp_template.template_type === 'IMAGE' || this.whatsapp_campaign.whatsapp_template.template_type === 'VIDEO'
            }
        },

        acceptedFileTypes() {
            return this.whatsapp_campaign.whatsapp_template.template_type === 'IMAGE' ? this.template_message_settings.header.image_accepted_types : this.template_message_settings.header.video_accepted_types
        },

        maxFileSizes() {
            return this.whatsapp_campaign.whatsapp_template.template_type === 'IMAGE' ? `${this.template_message_settings.header.image_size_limit}MB` : `${this.template_message_settings.header.video_size_limit}MB`
        }
    }
}
</script>