<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('macros.send_template_message') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <b-container>
                        <v-wait for="approved_template_messages">
                            <loading slot="waiting" />
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_account_name" class="font-weight-bold">
                                        {{ $t('simple.account') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_account_name" name="template_account_name"
                                        v-model="account.name" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_account_username" class="font-weight-bold">
                                        {{ $t('customer.phone') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_account_username" name="template_account_username"
                                        v-model="account.username" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="approved_templates" class="font-weight-bold">
                                        {{ $t('macros.template_name') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-select id="approved_templates" v-model="selected_template" @change="removeFile"
                                        :options="template_options" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>-- Please select an option
                                                --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </v-wait>

                        <ValidationObserver ref="main">
                            <template v-if="selected_template">
                                
                                
                                <template v-if="isHeaderActive">
                                    <b-card class="mt-4">
                                        <template #header>
                                            <span class="font-weight-bold">{{ $t('macros.header') }}</span>
                                        </template>
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="template_message_header_type" class="font-weight-bold">{{$t('macros.header_type') }}:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                <b-form-input id="template_message_header_type"
                                                    name="template_message_header_type"
                                                    v-model="template_details.template_type" disabled></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="align-items-center mb-3" v-if="template_details.template_type === 'TEXT'">
                                            <b-col sm="3">
                                                <label for="template_message_header_text" class="font-weight-bold">{{
                                                    $t('macros.header') }}:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                    <b-form-input id="template_message_vertical" v-model="template_details.header"
                                                        :maxlength="template_message_settings.header.text_limit" disabled></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="align-items-center mb-3" v-if="template_details.template_type !== 'TEXT'">
                                            <b-col sm="3">
                                                <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                            </b-col>
                                            <b-col sm="12" lg="4" class="mb-3">
                                                <div :class="{ 'isInvalid': !file_url }"
                                                    class="media-upload-container d-flex justify-content-center align-items-center">
                                                    <div class="position-absolute">
                                                        <div class="d-flex flex-column align-items-center text-center">
                                                            <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                                v-if="!file_url">{{ $t('macros.attach_file') }}</span>
                                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                                v-else>{{ $t('macros.change_file') }}</span>
                                                            <template v-if="template_details.template_type === 'VIDEO'">
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(MP4)</span>
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(Max 20 MB)</span>
                                                            </template>
                                                            <template v-else-if="template_details.template_type === 'IMAGE'">
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(JPEG or PNG)</span>
                                                                <span class="media-upload-inner-container text-muted font-weight-normal">(Max 2MB)</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <template v-if="template_details.template_type === 'IMAGE'">
                                                        <b-form-file id="template_message_media" v-model="file"
                                                            :accept="template_message_settings.header.image_accepted_types" plain
                                                            @input="addFile(file, template_message_settings.header.image_size_limit)"
                                                            class="media-upload-button h-100 w-100">
                                                        </b-form-file>
                                                    </template>
                                                    <template v-else-if="template_details.template_type === 'VIDEO'">
                                                        <b-form-file id="template_message_media" v-model="file"
                                                            :accept="template_message_settings.header.video_accepted_types" plain
                                                            @input="addFile(file, template_message_settings.header.video_size_limit)"
                                                            class="media-upload-button h-100 w-100">
                                                        </b-form-file>
                                                    </template>
                                                </div>
                                            </b-col>
                                            <b-col sm="4" v-if="file_url" class="mb-3">
                                                <div class="position-relative media-preview-container">
                                                    <template v-if="template_details.template_type === 'VIDEO'">
                                                        <video :src="file_url" class="media-preview"> </video>
                                                        <font-awesome-icon :icon="'play-circle'"
                                                        class="preview-video-button position-absolute p-0" />
                                                    </template>
                                                    <template v-else-if="template_details.template_type === 'IMAGE'">
                                                        <b-img fluid :src="file_url" class="media-preview" />
                                                    </template>
                                                    <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                                        v-if="file"
                                                        @click="removeFile">
                                                        <font-awesome-icon :icon="'times'" />
                                                    </b-btn>
                                                </div>
                                            </b-col>
                                        </b-row>
                               
                                    </b-card>
                                </template>

                                <b-card class="mt-4">
                                    <template #header>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="font-weight-bold">{{ $t('macros.body') }}</span>
                                        </div>
                                    </template>
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="template_message_content" class="font-weight-bold">
                                                {{ $t('macros.body_text') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <div class="position-relative">
                                                <b-form-textarea name="template_message_content"
                                                    v-model="template_details.content" rows="5" max-rows="6"
                                                    disabled></b-form-textarea>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <template v-if="template_details.content_variables_count">
                                    <b-card class="mt-4">
                                        <template #header>
                                            <span class="font-weight-bold">{{ $t('macros.template_variables') }}<span
                                                    class="text-danger">*</span></span>
                                        </template>
                                        <b-card bg-variant="light" border-variant="info" class="mb-1"
                                            v-for=" (variable) in template_variables" :key="variable.key">
                                            <b-row class="align-items-center">
                                                <b-col sm="3">
                                                    <label for="custom_variable_name" class="font-weight-bold">
                                                        {{ $t('macros.template_variable') }}:</label>
                                                    <b-form-input id="custom_variable_name" v-model="variable.key"
                                                        disabled>
                                                    </b-form-input>
                                                </b-col>
                                                <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                    :name="variable.value">
                                                    <b-col sm="8">
                                                        <label for="custom_variable_value" class="font-weight-bold">
                                                            {{ $t('macros.template_variable_value') }}<span
                                                                class="text-danger">*</span>:</label>
                                                        <b-form-input id="custom_variable_value"
                                                            v-model="variable.value" :state="valid"></b-form-input>
                                                    </b-col>
                                                </ValidationProvider>
                                            </b-row>
                                        </b-card>
                                    </b-card>
                                </template>
                                <template v-if="template_details.buttons">
                                    <b-card class="mt-4">
                                        <template #header>
                                            <span class="font-weight-bold">{{ $t('macros.buttons') }}</span>
                                        </template>
                                        <b-card bg-variant="light" border-variant="info" class="mb-1"
                                            v-for=" (button) in template_details.buttons" :key="button.text">
                                            <b-row class="align-items-center">
                                                <b-col :sm="button.type === 'URL' ? 6 : 3" >
                                                    <label for="button_list_option" class="font-weight-bold">
                                                        {{ $t('macros.button_type') }}</label>
                                                    <b-form-input id="button_list_option" v-model="button.type"
                                                        disabled>
                                                    </b-form-input>
                                                </b-col>
                                                <b-col :sm="button.type === 'PHONE_NUMBER' ? 4 : button.type === 'URL' ? 6 : 8">
                                                    <label for="button_text" class="font-weight-bold">
                                                        {{ $t('macros.button_text') }}:</label>
                                                    <b-form-input id="button_text" v-model="button.text"
                                                        disabled></b-form-input>
                                                </b-col>
                                                <b-col :sm="button.type === 'URL' ? 6 : 4" v-if="button.type === 'PHONE_NUMBER'">
                                                    <label for="button_phone" class="font-weight-bold">
                                                        {{ $t('simple.phone_number') }}:</label>
                                                    <b-form-input id="button_phone" v-model="button.phone_number"
                                                        disabled></b-form-input>
                                                </b-col>
                                                <b-col :sm="button.type === 'URL' ? 6 : 4" v-if="button.type === 'URL'">
                                                <label for="button_phone" class="font-weight-bold">
                                                  {{ $t('simple.url') }}:</label>
                                                <b-form-input id="button_url" v-model="button.url" disabled></b-form-input>
                                              </b-col>
                                                <b-col :sm="button.type === 'URL' ? 6 : 4" v-if="button.type === 'URL' && button.example">
                                                <label for="button_variable" class="font-weight-bold">
                                                  {{ $t('simple.url_variable') }}:</label>
                                                <b-form-input id="button_variable" v-model="button.variable"></b-form-input>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-card>
                                </template>

                            </template>
                        </ValidationObserver>
                    </b-container>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                    <b-button @click="send" variant="primary" :disabled="disabled">{{$t('simple.send')}}</b-button>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    async created() {
        await this.fetchApprovedTemplateMessages([this.account])
    },

    data() {
        return {
            selected_template: null,

            file_url: '',
            file: null,

        }
    },


    computed: {
        ...mapGetters({
            account: 'Accounts/getAccountData',
            approved_templates: 'Templates/getApprovedTemplateMessages',
            template_message_settings: 'Templates/getTemplateMessageSettings',
            fileID: 'Reply/getAttachFiles',
        }),

        template_options() {
            return this.approved_templates.flatMap(template => template.name)
        },

        template_details() {
            const template = this.approved_templates.filter(template => template.name === this.selected_template)[0]
            this.updateSelectedTemplateId(template.id)
            return template
        },

        disabled() {
            if (this.selected_template === null) return true
            return false
        },

        isHeaderActive() {
            return this.template_details.header || this.template_details.template_type === 'IMAGE' || this.template_details.template_type === 'VIDEO'
        },

        template_variables() {
            const variables = this.template_details.content.match(/{{\d}}/g) ?? []
            const custom_variables = []
            variables.forEach(variable => {
                custom_variables.push({
                    key: variable,
                    value: ''
                })
            })
            return custom_variables
        },

        acceptedFileTypes() {
            return this.template_details.template_type === 'IMAGE' ? this.template_message_settings.header.image_accepted_types : this.template_message_settings.header.video_accepted_types
        },

        maxFileSizes() {
            return this.template_details.template_type === 'IMAGE' ? `${this.template_message_settings.header.image_size_limit}MB` : `${this.template_message_settings.header.video_size_limit}MB`
        }
    },

    methods: {
        ...mapActions('Templates', [
            'fetchApprovedTemplateMessages',
            'updateSelectedTemplateId',
            'updateSelectedTemplateVariables',
            'updateSelectedTemplateButtonsVariables'
        ]),

        ...mapActions('Reply', [
            'sendText',
            'uploadFile',
        ]),

        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    this.file_url = URL.createObjectURL(file);

                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));

                    this.removeFile();
                }
            }
        },

        

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        removeFile() {
            this.file = null
            this.file_url = ''
            if(this.template_details.attachment) {
                this.file_url = this.template_details.attachment
            } 
        },

        async send() {
            const isValid = await this.$refs.main.validate();
            if (!isValid) return

            if(this.file) {
                const formData = new FormData();
                formData.append('files', this.file);
                formData.append('account_id', this.account.id);

                await this.uploadFile(formData)
            }
            
            const content_variables = this.template_variables.flatMap( variable => variable.value)
            this.updateSelectedTemplateVariables(content_variables)
            this.updateSelectedTemplateButtonsVariables(this.template_details.buttons)

            await this.sendText()

            this.$close(true);
        },



    }
}

</script>