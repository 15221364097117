<template>

    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.accounts') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add') }}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.accounts_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">
                    <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-danger"
                        v-if="fail_error">
                        <button type="button" aria-label="Close" class="close" @click="closeAlert">×</button>
                        {{ fail_error }}
                    </div>

                    <v-wait for="accounts">
                        <loading slot="waiting" />
                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                        <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <div>
                            <template v-if="window_width >= 1200">
                                <b-table responsive bordered hover :items="accounts" :fields="account_fields"
                                    :filter="filter">

                                    <!-- Integration -->
                                    <template slot="cell(integration)" slot-scope="data">
                                        <b-badge :variant="'primary'">

                                            <font-awesome-icon :icon="['fab', data.value.icon]" />

                                            {{ data.value.name }}

                                        </b-badge>
                                    </template>

                                    <!-- Team -->
                                    <template slot="cell(team)" slot-scope="data">
                                        <b-badge :variant="'info'">

                                            {{ data.value.display_name }}

                                        </b-badge>
                                    </template>

                                    <!-- Created at -->
                                    <template slot="cell(created_at)" slot-scope="cell">

                                        {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                    </template>

                                    <!-- Updated at -->
                                    <template slot="cell(updated_at)" slot-scope="cell">

                                        {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                    </template>

                                    <!-- Status -->
                                    <template slot="cell(status)" slot-scope="cell">
                                        <template v-if="cell.item.is_active">
                                            <template v-if="cell.item.status == 1">
                                                <b-badge variant="success">{{ $t('simple.running') }}</b-badge>
                                            </template>
                                            <template v-else-if="cell.item.status == 0">
                                                <b-badge class="pointer" variant="danger" v-b-tooltip.hover
                                                    :title="cell.item.reason">{{ $t('simple.failing') }}</b-badge>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <b-badge variant="secondary" v-b-tooltip.hover :title="cell.item.reason">{{
                                                $t('default.disabled') }}</b-badge>
                                        </template>

                                    </template>

                                    <!-- Actions -->
                                    <template slot="cell(actions)" slot-scope="cell">
                                        <accounts-action-buttons :cell="cell" @changeActive="changeActive"
                                            @connect="connect(...arguments)" @jsonModal="jsonModal(...arguments)"
                                            @edit="edit(...arguments)" @remove="remove(...arguments)"
                                            @twitterConnect="twitterConnect(...arguments)"
                                            @whatsappProfileEdit="whatsappProfileEdit(...arguments)"></accounts-action-buttons>
                                    </template>

                                </b-table>
                            </template>
                            <template v-else>
                                <b-table stacked="md" small responsive bordered hover :items="accounts"
                                    :fields="account_fields_collapsed" :filter="filter">

                                    <!-- Integration -->
                                    <template slot="cell(integration)" slot-scope="data">
                                        <b-badge :variant="'primary'">
                                            <font-awesome-icon :icon="['fab', data.value.icon]" />
                                            {{ data.value.name }}
                                        </b-badge>
                                    </template>

                                    <!-- Team -->
                                    <template slot="cell(team)" slot-scope="data">
                                        <b-badge :variant="'info'">
                                            {{ data.value.display_name }}
                                        </b-badge>
                                    </template>

                                    <!-- Status -->
                                    <template slot="cell(status)" slot-scope="data">
                                        <template v-if="data.item.is_active">
                                            <template v-if="data.item.status == 1">
                                                <b-badge variant="success">{{ $t('simple.running') }}</b-badge>
                                            </template>
                                            <template v-else-if="data.item.status == 0">
                                                <b-badge class="pointer" variant="danger" v-b-tooltip.hover
                                                    :title="data.item.reason">{{ $t('simple.failing') }}</b-badge>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <b-badge variant="secondary" v-b-tooltip.hover :title="data.item.reason">{{
                                                $t('default.disabled') }}</b-badge>
                                        </template>
                                    </template>

                                    <!-- Actions -->
                                    <template #cell(actions)="cell">


                                        <b-button size="sm" @click="cell.toggleDetails()">
                                            {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                        </b-button>

                                        <accounts-action-buttons :cell="cell" @changeActive="changeActive"
                                            @connect="connect(...arguments)" @jsonModal="jsonModal(...arguments)"
                                            @edit="edit(...arguments)" @remove="remove(...arguments)"
                                            @twitterConnect="twitterConnect(...arguments)"
                                            @whatsappProfileEdit="whatsappProfileEdit(...arguments)"></accounts-action-buttons>


                                    </template>

                                    <template #row-details="row">
                                        <b-table small stacked="md" responsive bordered hover :items="[row.item]"
                                            :fields="account_fields_not_collapsed">
                                            <!-- Created at -->
                                            <template slot="cell(created_at)" slot-scope="cell">

                                                {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                            </template>

                                            <!-- Updated at -->
                                            <template slot="cell(updated_at)" slot-scope="cell">

                                                {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                            </template>
                                        </b-table>
                                    </template>
                                </b-table>
                            </template>
                        </div>

                    </v-wait>

                </div>


            </div>


        </div>

    </div>

</template>

<script>

import AccountAdd from './Modal/AccountAdd.vue';
import AccountEdit from './Modal/AccountEdit.vue';
import AccountDelete from './Modal/AccountDelete.vue';
import AccountJson from './Modal/AccountJson.vue';


import { create } from 'vue-modal-dialogs'
import AccountInstagramSelect from "./Modal/AccountInstagramSelect";
import AccountFacebookSelect from "./Modal/AccountFacebookSelect";
import AccountLinkedinSelect from "./Modal/AccountLinkedinSelect";
import AccountWhatsappSelect from "./Modal/AccountWhatsappSelect.vue";
import AccountGoogleReviewSelect from "./Modal/AccountGoogleReviewSelect";
import TwitterConnect from './Modal/TwitterConnect.vue';
import WhatsappProfileEdit from './Modal/WhatsappProfileEdit.vue';


import { mapActions, mapGetters } from "vuex";

export default {



    mounted: function () {
        // Component mounted
        console.log('Account component mounted.');
        if (this.$route.query.action && this.$route.query.id) {
            if (this.$route.query.action === 'select-account') {
                // Instagram query parameter
                if (this.$route.query.integration === 'instagram') {
                    this.instagram_select_account(this.$route.query.id, this.$route.query.integration);
                }
                // Facebook query parameter
                if (this.$route.query.integration === 'facebook') {
                    this.facebook_select_account(this.$route.query.id, this.$route.query.integration);
                }
                // Google Review query parameter
                if (this.$route.query.integration === 'googlereview') {
                    this.googleReview_select_account(this.$route.query.id, this.$route.query.integration);
                }
                // LinkedIn query parameter
                if (this.$route.query.integration === 'linkedin') {
                    this.linkedin_select_account(this.$route.query.id, this.$route.query.integration);
                }
                // WhatsApp query parameter
                if (this.$route.query.integration === 'whatsapp') {
                    this.whatsapp_select_account(this.$route.query.id, this.$route.query.integration);
                }
            } else if (this.$route.query.action === 'show-applications') {
                if (this.$route.query.integration === 'twitter') {
                    this.twitterConnect(this.$route.query.id);
                }
            }


            console.log(this.$route.query.action);
        }

        if (this.$route.query.fail) {
            this.updateFailError(this.$route.query.fail)
        }

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })

        // console.log(this.integrations);

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    destroyed() {
        // Component destroyed
        console.log('Account Setting component destroyed.');
    },

    created: function () {
        console.log('Account Setting component created.');

        this.fetchAccounts().then((response) => {
        });

    },


    data: function () {
        return {
            window_width: window.innerWidth,
            integrations: [],
            filter: null,
            account_fields: [
                { key: 'integration', label: this.$t('simple.integration') },
                { key: 'name', label: this.$t('simple.name'), sortable: true },
                { key: 'username', label: this.$t('simple.username'), sortable: true },
                { key: 'phone', label: this.$t('simple.phone'), sortable: true },
                { key: 'team', label: this.$t('simple.team') },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],
            account_fields_collapsed: [
                { key: 'integration', label: this.$t('simple.integration') },
                { key: 'name', label: this.$t('simple.name'), sortable: true },
                { key: 'team', label: this.$t('simple.team') },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],
            account_fields_not_collapsed: [
                { key: 'username', label: this.$t('simple.username') },
                { key: 'phone', label: this.$t('simple.phone') },
                { key: 'created_at', label: this.$t('simple.created') },
                { key: 'updated_at', label: this.$t('simple.updated') },
            ],

        }
    },

    metaInfo: function () {

        return {
            title: this.$t('page.accounts'),
        }

    },

    methods: {
        ...mapActions('Accounts', [
            'updateFailError',
            'fetchAccounts',
            'changeAccountActive'
        ]),

        closeAlert: function () {
            this.updateFailError('');
        },

        connect: async function (id, integration, username) {

            if (integration === 'twitter') {
                window.location = '/api/v1/twitter/login/' + id;
            } else if (integration === 'facebook') {
                window.location = '/api/v1/facebook/login/' + id;
            } else if (integration === 'instagram') {
                window.location = '/api/v1/instagram/login/' + id;
            } else if (integration === 'youtube') {
                window.location = '/api/v1/youtube/login/' + id;
            } else if (integration === 'googleplay') {
                window.location = '/api/v1/googlePlay/login/' + id;
            } else if (integration === 'googlereview') {
                window.location = '/api/v1/googleReview/login/' + id;
            } else if (integration === 'linkedin') {
                window.location = '/api/v1/linkedIn/login/' + id;
            } else if (integration === 'whatsapp') {
                window.location = '/api/v1/whatsapp/login/' + id;
            }
        },


        add: async function () {
            // Show account add modal
            const account_add = create(AccountAdd);

            if (await account_add()) {
                // Refresh account list
                this.fetchAccounts();
            }

        },

        edit: async function (id) {
            // Show account edit modal
            const account_edit = create(AccountEdit, 'id');

            if (await account_edit(id)) {
                // Refresh account list
                this.fetchAccounts();
            }

        },

        async changeActive(id) {
            await this.changeAccountActive(id)
        },

        whatsappProfileEdit: async function (id) {
            // Show account edit modal
            const whatsapp_profile_edit = create(WhatsappProfileEdit, 'id');

            await whatsapp_profile_edit(id)

        },

        jsonModal: async function (id) {
            // Show account edit modal
            const account_json_file = create(AccountJson, 'id');
            if (await account_json_file(id)) {
                // Refresh account list
                this.fetchAccounts();
            }
        },

        remove: async function (id) {

            // Show account remove modal
            const account_delete = create(AccountDelete, 'id');

            if (await account_delete(id)) {
                // Refresh account list
                this.fetchAccounts();
            }

        },

        instagram_select_account: async function (id, integration) {

            // Show instagram select account modal
            const account_instagram_select = create(AccountInstagramSelect, 'id', 'integration');

            if (await account_instagram_select(id, integration)) {
                // Refresh account list
                this.fetchAccounts();
            }

        },

        facebook_select_account: async function (id, integration) {

            // Show instagram select account modal
            const account_facebook_select = create(AccountFacebookSelect, 'id', 'integration');

            if (await account_facebook_select(id, integration)) {
                // Refresh account list
                this.fetchAccounts();
            }

        },

        googleReview_select_account: async function (id, integration) {

            console.log('googleeeee')
            // Show instagram select account modal
            const account_googleReview_select = create(AccountGoogleReviewSelect, 'id', 'integration');

            if (await account_googleReview_select(id, integration)) {
                // Refresh account list
                this.fetchAccounts();
            }

        },


        twitterConnect: async function (id) {
            const twitter_connect = create(TwitterConnect, 'id');
            if (await twitter_connect(id)) {
                this.fetchAccounts();
            }
        },

        onResize() {
            this.window_width = window.innerWidth;
        },

        linkedin_select_account: async function (id, integration) {

            // Show LinkedIn select account modal
            const account_linkedin_select = create(AccountLinkedinSelect, 'id', 'integration');

            if (await account_linkedin_select(id, integration)) {
                // Refresh account list
                this.getAccounts();
            }

        },

        whatsapp_select_account: async function (id, integration) {
            console.log('whatsapp')
            // Show WhatsApp select account modal
            const account_whatsapp_select = create(AccountWhatsappSelect, 'id', 'integration');

            if (await account_whatsapp_select(id, integration)) {
                // Refresh account list
                this.getAccounts();
            }

        },

    },
    computed: {
        ...mapGetters({
            fail_error: 'Accounts/getFailError',
            accounts: 'Accounts/getAccounts',
            integration_list: 'Accounts/getIntegrationAccountList',
        })
    },
}
</script>