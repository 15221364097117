import {isNull} from "bootstrap-vue/esm/utils/inspect";

const state = {

  // Accounts
  accounts: [],

  disabled_areas: {
    draft_options: false,
    answer_options: false,
    question_options: false,
    messages_list_action_trash: false,
    messages_confirmation: false,
    messages_list_action_recycle: false,
    messages_list_action_reply: false,
    reply_area: false,
    customer_edit: false,
    ticket_edit: false,
    whatsapp_templates: false,
    whatsapp_campaigns: false,
  },

  // Account id
  account: null,

  // Integration lists account
  integration_accounts: [],

  // Sender Account
  sender_account: null,

  // Can Send Comment
  can_comment: true,

  // Instagram selection account list
  instagram_account_list: null,

  // facebook selection account list
  facebook_account_list: null,

  // whatsapp selection account list
  whatsapp_account_list: null,

  // google review selection account list
  googleReview_account_list: null,

  //Twitter Applications to authorize
  twitter_applications: null,

  // LinkedIn selection account list
  linkedin_account_list: null,

  // Account data are hold here
  account_data: null,

  // Integration id
  integration_id: null,

  fail_error: '',

  // Integrations
  integrations : [
    {
      id: 'twitter',
      color:'info',
      icon:'twitter',
      types: [
                {
                  disabled: false,
                  name: 'DM',
                  type: 1,
                  limit: 10000,
                  attachments: true,
                  attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
                  attachment_image_types: 'image/jpeg, image/png',
                  attachment_video_types: 'image/gif, video/mp4',
                  attachment_multiple: false,
                  attachment_max_file_count: 1,
                  attachment_max_file_size: '5MB',
                  attachment_max_video_count: 1,
                  attachment_max_gif_count: 1,
                  attachment_video_type: 'video/mp4',
                  attachment_gif_type: 'image/gif',

                },
                {
                  disabled: false,
                  name: 'Mention',
                  type: 2,
                  limit: 265,
                  attachments: true,
                  attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
                  attachment_image_types: 'image/jpeg, image/png',
                  attachment_video_types: 'image/gif, video/mp4',
                  attachment_multiple: true,
                  attachment_max_file_count: 4,
                  attachment_max_file_size: '5MB',
                  attachment_max_video_count: 1,
                  attachment_max_gif_count: 1,
                  attachment_video_type: 'video/mp4',
                  attachment_gif_type: 'image/gif',
                },
            ]
    },
    {
      id: 'whatsapp',
      color: 'success',
      icon:'whatsapp',
      types: [
        {
          disabled: false,
          name: 'Message',
          type: 1,
          limit: 10000,
          attachments: true,
          attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_video_types: 'image/gif, video/mp4',
          attachment_multiple: false,
          attachment_max_file_count: 1,
          attachment_max_file_size: '5MB',
          attachment_max_video_count: 1,
          attachment_max_gif_count: 1,
          attachment_video_type: 'video/mp4',
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    {
      id: 'facebook',
      color: 'primary',
      icon:'facebook',
      types: [
        {
          disabled: false,
          name: 'Messenger',
          type: 1,
          limit: 2000,
          attachments: true,
          attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_video_types: 'image/gif, video/mp4',
          attachment_multiple: false,
          attachment_max_file_count: 1,
          attachment_max_file_size: '5MB',
          attachment_max_video_count: 1,
          attachment_max_gif_count: 1,
          attachment_video_type: 'video/mp4',
          attachment_gif_type: 'image/gif',

        },
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 2000,
          attachments: true,
          attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_video_types: 'image/gif, video/mp4',
          attachment_multiple: false,
          attachment_max_file_count: 4,
          attachment_max_file_size: '5MB',
          attachment_max_video_count: 1,
          attachment_max_gif_count: 1,
          attachment_video_type: 'video/mp4',
          attachment_gif_type: 'image/gif',
        },
      ]
    },
    {
      id: 'instagram',
      color: 'warning',
      icon:'instagram',
      types: [
        {
          disabled: false,
          name: 'DM',
          type: 1,
          limit: 10000,
          attachments: true,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: false,
          attachment_max_file_count: 1,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 0,
          attachment_max_gif_count: 1,
          attachment_gif_type: 'image/gif',

        },
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 2200,
          attachments: false,
          attachment_types: 'image/jpeg, image/png, image/gif, video/mp4',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_video_types: 'image/gif, video/mp4',
          attachment_multiple: false,
          attachment_max_file_count: 4,
          attachment_max_file_size: '5MB',
          attachment_max_video_count: 1,
          attachment_max_gif_count: 1,
          attachment_video_type: 'video/mp4',
          attachment_gif_type: 'image/gif',
        },
      ]
    },
    {
      id: 'sikayetvar',
      color: 'danger',
      icon:'hand-holding-usd',
      types: [
        {
          disabled: false,
          name: 'Message',
          type: 1,
          limit: 10000,
          attachments: true,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: true,
          attachment_max_file_count: 10,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 10,
          attachment_max_gif_count: 10,
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    {
      id: 'youtube',
      color: 'danger',
      icon:'youtube',
      types: [
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 10000,
          attachments: false,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: false,
          attachment_max_file_count: 10,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 10,
          attachment_max_gif_count: 10,
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    {
      id: 'googleplay',
      color: 'primary',
      icon:'google-play',
      types: [
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 10000,
          attachments: false,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: false,
          attachment_max_file_count: 10,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 10,
          attachment_max_gif_count: 10,
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    {
      id: 'googlereview',
      color: 'primary',
      icon:'google',
      types: [
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 10000,
          attachments: false,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: false,
          attachment_max_file_count: 10,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 10,
          attachment_max_gif_count: 10,
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    {
      id: 'linkedin',
      color: 'primary',
      icon:'linkedin',
      types: [
        {
          disabled: false,
          name: 'Comment',
          type: 2,
          limit: 1250,
          attachments: false,
          attachment_types: 'image/jpeg, image/png, image/gif',
          attachment_image_types: 'image/jpeg, image/png',
          attachment_multiple: false,
          attachment_max_file_count: 10,
          attachment_max_file_size: '8MB',
          attachment_max_video_count: 10,
          attachment_max_gif_count: 10,
          attachment_gif_type: 'image/gif',

        }
      ]
    },
    // {id: 'instagram'},
  ],

  // Integration types
  integration_types : null,

  // Integration type
  integration_type : null,

  // Tracks Limit
  tracks_limit : 5,

  // Tracks
  tracks : [],

  //WhatsApp Profile:

  whatsapp_profile_vertical_options: [
    { value: 'Automotive', text: 'Automotive' },
    { value: 'Beauty, Spa and Salon', text: 'Beauty, Spa and Salon'},
    { value: 'Clothing and Apparel', text: 'Clothing and Apparel'},
    { value: 'Education', text: 'Education'},
    { value: 'Entertainment', text: 'Entertainment'},
    { value: 'Event Planning and Service', text: 'Event Planning and Service'},
    { value: 'Finance and Banking', text: 'Finance and Banking'},
    { value: 'Food and Grocery', text: 'Food and Grocery'},
    { value: 'Public Service', text: 'Public Service'},
    { value: 'Hotel and Lodging', text: 'Hotel and Lodging'},
    { value: 'Medical and Health', text: 'Medical and Health'},
    { value: 'Non - profit', text: 'Non - profit'},
    { value: 'Professional Services', text: 'Professional Services'},
    { value: 'Shopping and Retail', text: 'Shopping and Retail'},
    { value: 'Travel and Transportation', text: 'Travel and Transportation'},
    { value: 'Restaurant', text: 'Restaurant'},
    { value: 'Other', text: 'Other'},
  ],

  whatsapp_profile_settings: {
    profile: {
      desc_limit: 512,
      website_limit: 128,
      email_limit: 128,
    },
    about: {
      text_limit: 139
    },
    profile_picture: {
      accepted_image_types: 'image/jpeg, image/png',
      max_image_file_size: 5, //MB
      min_image_width: 192, // px
      min_image_height: 192, // px
      max_image_width: 640, // px
      max_image_height: 640, // px
    }
  }

}

// getters
const getters = {
  
  // Get Accounts
  getAccounts: (state) => {
    // Return accounts
    return state.accounts;
  },

  // Get Account id
  getAccount: (state, getters) => {
    // Return account
    return state.account;
  },

  // Get Tracks
  getTracks: (state, getters) => {
    // Return account
    return state.tracks;
  },

  // Get Tracks Limit
  getTracksLimit: (state, getters) => {
    // Return account
    return state.tracks_limit;
  },

  // Get Account id
  getAccountID: (state, getters) => {

    if(state.account_data){

      return state.account_data.id;

    } else {

      return false;

    }

  },

  // Get Account id
  getAccountData: (state, getters) => {
    // Return account data
    return state.account_data;
  },

  getDisabledAreas: (state) => {
    // Return account data
    return state.disabled_areas;
  },

  getIntegrations: (state) => {
    return state.integrations;
  },

  // Get Account Integration
  getIntegration: (state, getters) => {

    // Check if have integrations
    if(state.integrations){

      // Return params from
      return state.integrations.find( integration => integration.id === state.integration_id );

    } else {

      return false;

    }
  },

  // Get Account Integration ID
  getIntegrationID: (state, getters) => {
    // Return integration ID
    return state.integration_id;
  },

  // Get Account Integration Type
  getIntegrationTypes: (state, getters) => {
    // Return integration types
    return state.integration_types;
  },

  // Get Account Integration Type
  getIntegrationType: (state, getters) => {

    // Check if we have integration types
    if(state.integration_types){

      // Find account and set account data
      let integration_type = state.integration_types.find( integration_type => integration_type.type === state.integration_type );

      // Return integration type
      return integration_type;

    } else {

      return false;

    }

  },

  // Get Account Active Integration Type ID
  getIntegrationTypeID: (state, getters) => {

    let integration_type = getters.getIntegrationType;

    // Check if we have integration types
    if(integration_type){

      // Return integration type
      return integration_type.type;

    } else {

      return false;

    }

  },


  // Get Account Active Integration Type Limit
  getIntegrationTypeLimit: (state, getters) => {

    let integration_type = getters.getIntegrationType;

    // Check if we have integration types
    if(integration_type){

      // Return integration type
      return integration_type.limit;

    } else {

      return false;

    }

  },


  // Get accounts by type
  getAccountsByType: (state) => {

    // Return results
    return results => state.accounts.find( account => account.integration_id === state.integration_id );

    // I don't know what to do with this?
    /*return keyword => state.accounts.filter(account =>{
       return account.integration_id === keyword
    })

    .map(account => {

      const {...rest} = account;

      return {

        ...rest,

        value: account.id,

        text: account.name,
      }

    }); */


  },

  // Get instagram account list (Get from Facebook)
  getInstagramAccountList: (state) => {
    // Return instagram accounts
    return state.instagram_account_list;
  },

  // Get facebook account list (Get from Facebook)
  getFacebookAccountList: (state) => {
    // Return facebook accounts
    return state.facebook_account_list;
  },

  // Get whatsapp account list (Get from Facebook)
  getWhatsappAccountList: (state) => {
    // Return whatsapp accounts
    return state.whatsapp_account_list;
  },

  // Get google review account list (Get from Google)
  getGoogleReviewAccountList: (state) => {
    // Return google review accounts
    return state.googleReview_account_list;
  },

  getTwitterApplications: (state) => {
    return state.twitter_applications
  },

  // Get LinkedIn account list (Get from LinkedIn)
  getLinkedInAccountList: (state) => {
    // Return LinkedIn accounts
    return state.linkedin_account_list;
  },

  // Get Integration Account Lists
  getIntegrationAccountList: (state) => {
    // Return integration account list
    return state.integration_accounts;
  },

  // Get Sender Account
  getSenderAccount: (state) => {
    // Return Sender Account
    return state.sender_account;
  },

  // Get Can Comment
  getCanComment: (state) => {
    // Return Can Comment
    return state.can_comment;
  },

  getFailError: (state) => {
    return state.fail_error;
  },

  getWhatsappProfileVerticalOptions: (state) => {
    return state.whatsapp_profile_vertical_options;
  },

  getWhatsappProfileSettings: (state) => {
    return state.whatsapp_profile_settings;
  },




}

// mutations
const mutations = {

  // Set account
  setAccount (state, account) {
    state.account = account;
  },

  // Set account data
  setAccountData (state, account_data) {
    state.account_data = account_data;
  },

  setDisabledAreas (state, status) {
    const areas = {...state.disabled_areas}
    Object.keys(areas).forEach(key => {
      areas[key] = status;
    });
    state.disabled_areas = areas;
  },

  // Set account integration id
  setIntegrationID (state, integration_id) {
    state.integration_id = integration_id;
  },

  // Set account integration types
  setIntegrationTypes (state, integration_types) {
    state.integration_types = integration_types;
  },

  // Set account integration type
  setIntegrationType (state, integration_type) {
    state.integration_type = integration_type;
  },

  // Set accounts
  setAccounts (state, accounts){
    state.accounts = accounts;
  },

  // Set Tracks
  setTracks (state, tracks){
    state.tracks = tracks;
  },

  // Set instagram account list (Get from Facebook)
  setInstagramAccountList (state, instagram_account_list){
    state.instagram_account_list = instagram_account_list;
  },

  // Set facebook account list (Get from Facebook)
  setFacebookAccountList (state, facebook_account_list){
    state.facebook_account_list = facebook_account_list;
  },

  // Set whatsapp account list (Get from WhatsApp)
  setWhatsappAccountList (state, whatsapp_account_list){
    state.whatsapp_account_list = whatsapp_account_list;
  },

  // Set google review account list (Get from Google)
  setGoogleReviewAccountList (state, googleReview_account_list){
    state.googleReview_account_list = googleReview_account_list;
  },

  setTwitterApplications(state, twitter_applications) {
    state.twitter_applications = twitter_applications
  },

  // Set LinkedIn account list (Get from LinkedIn)
  setLinkedInAccountList (state, linkedin_account_list){
    state.linkedin_account_list = linkedin_account_list;
  },

  // Set Integration account list
  setIntegrationAccountList (state, integration_account_list){
    state.integration_accounts = integration_account_list;
  },

  // Set Sender Account
  setSenderAccount (state, sender_account){
    state.sender_account = sender_account;
  },

  // Set Can Comment
  setCanComment (state, can_comment){
    state.can_comment = can_comment;
  },

  setFailError (state, fail_error){
    state.fail_error = fail_error;
  },

}

// actions
const actions = {
  
  // get account
  async fetchAccounts({ dispatch, commit }, data){

    // Start waiting
    dispatch('wait/start', 'accounts', { root: true });

    let accounts_api = '/api/v1/system/accounts/';

    // Get Accounts
    await axios.get(accounts_api)

      .then((response) => {

        // Assign accounts
        commit('setAccounts', response.data);

        dispatch('updateIntegrationAccountList');

        // Stop waiting
        dispatch('wait/end', 'accounts', { root: true });

      })
        .then(() => {

          if(data){
            dispatch('setAccount', data);
          }

        })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'accounts', { root: true });

      });

  },

  // get instagram or facebook account list
   fetchAccountList({ dispatch, commit }, {id, integration}){

    // Start waiting
    dispatch('wait/start', 'account-list', { root: true });

    let accounts_api = '/api/v1/settings/account/selection/';

    // Get Accounts
    return new Promise( (resolve, reject) => {
      axios.get(accounts_api + id + '/' + integration)

          .then((response) => {

            // Instagram
            if (response.data.integration === 'instagram') {
              // Assign accounts
              commit('setInstagramAccountList', response.data);
            }

            // Facebook
            if (response.data.integration === 'facebook') {
              // Assign accounts
              commit('setFacebookAccountList', response.data);
            }

            // Google Review
            if (response.data.integration === 'googlereview') {
              // Assign accounts
              commit('setGoogleReviewAccountList', response.data);
            }

            // LinkedIn
            if (response.data.integration === 'linkedin') {
              // Assign accounts
              commit('setLinkedInAccountList', response.data);
            }

            // WhatsApp
            if (response.data.integration === 'whatsapp') {
              // Assign accounts
              commit('setWhatsappAccountList', response.data);
            }

            // Stop waiting
            dispatch('wait/end', 'account-list', {root: true});

            resolve(response);

          })
          .catch((error) => {
            // Show error
            // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

            commit('setFailError', error.response.data.message);

            // Log to console
            console.log(error);

            // Stop waiting
            dispatch('wait/end', 'account-list', {root: true});

            reject(error)

          });
    })

  },

  // choose instagram or facebook account from list
  async chooseAccount({ dispatch, commit }, {id, account_data, integration}){

    // Start waiting
    dispatch('wait/start', 'choose-account', { root: true });

    console.log('id:' + id);
    console.log('account_data:' + account_data);
    console.log('integration:' + integration);

    let accounts_api = '/api/v1/settings/account/selection/';

    // Send selected account data
    await axios.post(accounts_api + id, {

      // Selected Account Data
      account: account_data,

      // Selected Account Integration
      integration: integration

    }).then((response) => {

        // Show success
        Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

        // Stop waiting
        dispatch('wait/end', 'choose-account', { root: true });

      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'choose-account', { root: true });

      });

  },

  // Get Tracks
  async fetchTracks({ dispatch, commit }, id){

    // Loading start
    dispatch('wait/start', 'tracks', { root: true });

    let tracks_api = '/api/v1/twitter/tracks';

    // Get tracks
    await axios.get(tracks_api + '?account_id='+ id)
        .then((response) => {
          // response data
          // Set account
          commit('setTracks', response.data);
          // console.log(response.data);

          // End waiting
          dispatch('wait/end', 'tracks', { root: true });


        })
        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // End waiting
          dispatch('wait/end', 'tracks', { root: true });

        });

  },

  // Set account
  async setAccount({ dispatch, commit}, id) {

    // Set account
    commit('setAccount', id);

    // Set Account Data
    dispatch('setAccountData');

  },

  async updateDisabledAreas({ dispatch, commit}, status) {

    // Set account
    commit('setDisabledAreas', status);


  },

  // Set current account data
  async setAccountData({ dispatch, state, commit}) {

    // Find account and set account data
    let account_data = state.accounts.find( account => account.id == state.account );

    // Set integration id
    commit('setIntegrationID', account_data.integration_id);

    // Set Account Integration Types
    dispatch('setIntegrationTypes');

    // Set account data
    commit('setAccountData', account_data);

    if(!account_data.is_active) {
      dispatch('updateDisabledAreas', true);
    } else {
      dispatch('updateDisabledAreas', false);
    }
    
  },

  // Update Unread_count On Accounts
  updateUnreadCount({ dispatch, state, commit}, notif) {

    const account = state.accounts.find(account => account.id === notif['account_id']);
    if(account) {
      Object.assign(account, {'unread_count': notif['unread_count']});
      if (!isNull(notif['customer_id'])) {
        dispatch('Inbox/updateCustomerUnreadCount', notif['customer_id'], {root: true});
      }
    }

  },

  // Update Confirmation_count On Accounts
  updateConfirmationCount({ dispatch, state, commit}, notif) {

    const account = state.accounts.find(account => account.id === notif['confirmation_account_id']);
    Object.assign(account, {'confirmation_count':notif['confirmation_pending']});

  },

  // Update Transfer_count On Accounts
  updateTransferCount({ dispatch, state, commit}, notif) {

    const account = state.accounts.find(account => account.id === notif['account_id']);
    Object.assign(account, {'transfer_count':notif['transfer_count']});

  },

  // Separate accounts by their integrations
  updateIntegrationAccountList({ state, commit }) {

    let final_list = [];
    state.integrations.forEach((item) => {
      final_list[item.id] = state.accounts.filter(account => account.integration_id === item.id);
    });

    // Set Integration accounts list
    commit('setIntegrationAccountList', final_list);

    console.log(state.integration_accounts);

  },

  // Set current account integration types
  async setIntegrationTypes({ dispatch, state, commit}) {

    // Find integration id and set it types
    let integration = state.integrations.find( integration => integration.id === state.integration_id );

    // Set integration id
    commit('setIntegrationTypes', integration.types);

    // Set Integration type to default
    //dispatch('setIntegrationType', 1);

  },

  // Set current account integration type
  async setIntegrationType( { dispatch, commit }, integration_type) {

    // Set integration id
    commit('setIntegrationType', integration_type);

    // Reset attachments
    dispatch('Reply/updateAttachmentStatus', false, { root: true });
    
  },

  // Set Sender account
  async updateSenderAccount({ dispatch, commit}, id) {

    // Set Sender account
    commit('setSenderAccount', id);

  },

  // Update Can Comment
  async updateCanComment({ dispatch, commit}, status) {

    // Set Can Comment
    commit('setCanComment', status);

  },

  async updateFailError({commit}, error) {

    commit('setFailError', error);

  },

  async fetchTwitterApplications({dispatch, commit}, id ) {

    dispatch('wait/start', 'twitter_applications', { root: true });

    let twitter_applications_api = '/api/v1/twitter/applications/';

    axios.get(twitter_applications_api + id)
        .then( (response) => {

          commit('setTwitterApplications',response.data )

          dispatch('wait/end', 'twitter_applications', { root: true });
          
        })
        .catch((error) => {
            // Show error
            Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

            // Log to console
            console.log(error);
            // End waiting
            dispatch('wait/end', 'twitter_applications', { root: true });
        });

  },

  async changeAccountActive({dispatch, commit}, id ) {

    dispatch('wait/start', 'accounts', { root: true });

    let accounts_api = '/api/v1/settings/accounts/';

    // Get Accounts
    await axios.patch(accounts_api + id)
    .then((response) => {

      console.log(response)
      // Show success
      Vue.toasted.success(Vue.i18n.translate('simple.success_info'));


    })
    .catch((error) => {

      // Show error
      Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

    });

    dispatch('wait/end', 'accounts', { root: true });

  },

  updateTwitterLimitation({ dispatch, state, commit}, notification) {
    const account = state.accounts.find(account => account.id === notification['account']['id']);
    Object.assign(account, {
      'additional_data' : notification['account']['additional_data']
    });
    console.log(account)
  },




}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}