<template>

    <div class="modal fade show d-block ps--scrolling-y" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">

                    <div class="form-group">

                        <label for="integration">{{ $t('simple.integration') }}</label>

                        <v-wait for="integrations">

                            <loading slot="waiting"/>

                            <multiselect
                                v-validate="'required'"
                                data-vv-name="integration"
                                data-vv-value-path="integration"
                                v-model="integration"
                                :options="integrations"
                                :allow-empty="false"
                                :searchable="false"
                                :show-labels="false"
                                track-by="name"
                                label="name"
                                placeholder="Select one"
                                name="integration"
                                id="integration"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <font-awesome-icon :icon="[option.id !== 'sikayetvar' ? 'fab' : 'fas', option.icon]" />
                                    {{ option.name }}
                                </template>
                                <template slot="option" slot-scope="{ option }">
                                    <font-awesome-icon :icon="[option.id !== 'sikayetvar' ? 'fab' : 'fas', option.icon]" />
                                    {{ option.name }}
                                </template>

                            </multiselect>

                        </v-wait>



                        <small v-show="errors.has('integration')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('integration') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="teams">{{ $t('default.team') }}</label>

                        <v-wait for="teams">

                            <loading slot="waiting"/>

                            <multiselect
                                    v-validate="'required'"
                                    data-vv-name="teams"
                                    data-vv-value-path="teams"
                                    v-model="team"
                                    :options="teams"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :allow-empty="false"
                                    :searchable="false"
                                    :show-labels="false"
                                    track-by="name"
                                    label="name"
                                    placeholder="Select one"
                                    name="teams"
                                    id="teams"
                            />

                            <small v-show="errors.has('teams')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('teams') }}</small>

                        </v-wait>
                    </div>


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group" v-if="this.integration.id && (this.integration.id === 'twitter' || this.integration.id === 'facebook' || this.integration.id === 'instagram' || this.integration.id === 'youtube' || this.integration.id === 'googleplay' || this.integration.id === 'linkedin')">

                        <label for="username">{{ $t('simple.username') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="username"
                            name="username"
                            v-validate="'required|excluded:'+ this.accounts.filter(o => o.integration_id == integration.id).map(i => i.username.toLowerCase())"
                            v-model="username"
                            @input="username = $event.target.value.toLowerCase()"
                        >

                        <small v-show="errors.has('username')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('username') }}</small>

                    </div>

                    <div class="form-group" v-if="this.integration.id && this.integration.id === 'sikayetvar'">

                        <label for="api_token">{{ $t('simple.api_token') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="api_token"
                            name="api_token"
                            v-validate="'required'"
                            v-model="api_token"
                        >

                        <small v-show="errors.has('api_token')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('api_token') }}</small>
                        <small v-if="custom_error" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ custom_error }}</small>

                    </div>

<!--                    <div class="form-group" v-if="this.integration.id && this.integration.id === 'instagram'">-->

<!--                        <label for="username">{{ $t('simple.password') }}</label>-->

<!--                        <input-->
<!--                            class="form-control"-->
<!--                            type="password"-->
<!--                            id="password"-->
<!--                            name="password"-->
<!--                            v-validate="'required'"-->
<!--                            v-model="password"-->
<!--                        >-->

<!--                        <small v-show="errors.has('password')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('password') }}</small>-->

<!--                    </div>-->

                    <div class="form-group" v-if="this.integration.id && this.integration.id === 'whatsapp'">

                        <div class="form-group">

                            <label for="api_providers">{{ $t('simple.service_provider') }}</label>


                            <multiselect
                                    v-validate="'required'"
                                    data-vv-name="api_providers"
                                    data-vv-value-path="api_providers"
                                    v-model="api_provider"
                                    :options="api_providers"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :allow-empty="false"
                                    :searchable="false"
                                    :show-labels="false"
                                    track-by="id"
                                    label="name"
                                    placeholder="Select one"
                                    name="api_providers"
                                    id="api_providers"
                            />

                            <small v-show="errors.has('api_types')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('api_types') }}</small>

                        </div>

                        <label for="phone">{{ $t('simple.phone_number') }}</label>

                        <input
                            class="form-control"
                            type="number"
                            id="phone"
                            name="username"
                            v-validate="'required'"
                            v-model="phone"
                        >

                        <small v-show="errors.has('phone')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('phone') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="confirmation">{{ $t('confirmation.require') }}:</label>

                        <b-form-checkbox v-model="confirmation" switch>
                            {{ check_confirmation() }}
                        </b-form-checkbox>

                        <span></span>



                    </div>

                    <div class="form-group">

                        <b-alert variant="success" show>{{ $t('simple.integration_info') }}</b-alert>

                    </div>

                    <b-button size="sm" block type="submit" variant="success">{{ $t('simple.connect') }}</b-button>

                </form>

            </v-wait>

          </div>
        </div>
      </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Account Add Modal mounted');

            // Get integrations
            this.getIntegrations();

            // Get teams
            this.getTeams();
        },
        
        destroyed() {
            console.log('Account Add Modal destroyed.');
        },

        data: function() {

          return {

            accounts_api: '/api/v1/settings/accounts/',
            integrations_api: '/api/v1/settings/integrations',
            teams_api: '/api/v1/system/teams',

            integrations: [],
            api_providers: [
              {
                id: 'meta',
                name: 'Meta'
              },
              {
                id: 'waboxapp',
                name: 'WaboxApp'
              },
              {
                id: 'karix',
                name: 'Karix'
              },
              {
                id: 'gupshup',
                name: 'Gupshup'
              },
            ],
            teams: [],

            integration: '',
            api_provider: '',
            api_token: '',
            name: '',
            username: '',
            password: '',
            phone: '',
            confirmation: false,
            team: false,
            custom_error: '',

          }

        },

        methods: {

            getIntegrations: async function(){

                // Start waiting
                this.$wait.start('integrations');

                // Get integrations
                await axios.get(this.integrations_api)

                  .then((response) => {

                    // Response data
                    this.integrations = response.data;
                    // console.log('integration list: ' + this.integrations);

                    // Loading stop
                    this.$wait.end('integrations');

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Close modal
                    this.$close(true);

                    // Loading stop
                    this.$wait.end('integrations');

                  });
            },

            // Get teams
            getTeams: async function(){

                // Start waiting
                this.$wait.start('teams');

                // Get integrations
                await axios.get(this.teams_api)

                    .then((response) => {

                        // Response data
                        this.teams = response.data;
                        //console.log(response);

                        // Loading stop
                        this.$wait.end('teams');

                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Close modal
                        this.$close(true);

                        // Loading stop
                        this.$wait.end('teams');

                    });
            },

            account_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Add account
                await axios.post(this.accounts_api, {
                    integration: this.integration.id,
                    api_provider: this.api_provider.id,
                    api_token: this.api_token,
                    name: this.name,
                    username: this.username,
                    password: this.password,
                    phone: this.phone,
                    confirmation: this.confirmation,
                    team_id: this.team.id
                  })
                  .then((response) => {

                      // Show success
                      Vue.toasted.success(this.$t('simple.success_info'));

                      this.$wait.end('add');
                      // Close modal
                      this.$close(true);

                  })
                  .catch((error) => {

                      if(error.response.data.message) {
                          this.custom_error = error.response.data.message;
                      }else{
                          this.custom_error = '';
                      }


                    // Loading stop
                    this.$wait.end('add');

                    // Show error
                    if(error.response.status == 422){
                      let errorMsg = '';
                      for (const errorKey in error.response.data.errors) {
                        errorMsg = error.response.data.errors[errorKey][0]
                      }
                      Vue.toasted.error(errorMsg);
                    }else{
                      Vue.toasted.error(this.$t('simple.error_info'));
                    }

                    //console.log(error);

                    // Close modal
                    //this.$close(true);

                  });

            },

            check_confirmation: function() {
                if(this.confirmation){
                    return this.$t('default.enabled');
                } else {
                    return this.$t('default.disabled');
                }
            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.account_add();
                    }
                });
            },
        },
        computed: {
            // Map getters
            ...mapGetters({
                accounts: 'Accounts/getAccounts'
            })
        },
    }
</script>


